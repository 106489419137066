<template>
  <div class="m-2">

    <!-- Table Top -->
    <b-row>

      <!-- Per Page -->
      <b-col
        cols="12"
        md="6"
        class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
      >
        <!--        <label>{{ $t('shale') }}</label>-->
        <v-select
          :value="limit"
          :options="limitOptions"
          :clearable="false"
          :searchable="false"
          class="per-page-selector d-inline-block"
          @option:selected="changeLimitEvent"
        />
        <!--        <label>entries</label>-->
      </b-col>

      <!-- Search -->
      <b-col
        cols="12"
        md="6"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-form-input
            v-if="showSearch"
            :value="query"
            class="d-inline-block"
            type="search"
            :placeholder="$t('shared.search')"
            @keyup.enter="$emit('change:query', $event.target.value)"
            @blur="$emit('change:query', $event.target.value)"
            @clear="$emit('change:query', '')"
            @input="onQueryChange"
          />
          <b-button
            v-if="showFilterButton"
            v-b-toggle.filters-sidebar
            variant="outline-primary"
            class="ml-50"
            @click="$emit('click:filter')"
          >
            <span class="text-nowrap">{{ $t('shared.button.filter') }}</span>
          </b-button>
          <b-button
            v-if="showAddButton && hasAtLeastOneRole(me.roles, addButtonRoles)"
            variant="primary"
            class="ml-50"
            @click="$emit('click:add')"
          >
            <span class="text-nowrap">{{ $t(addLabel) }}</span>
          </b-button>
        </div>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import vSelect from 'vue-select'
import {
  BButton, BCol, BFormInput, BRow, VBToggle,
} from 'bootstrap-vue'
import { cloneNested } from '@/helpers/helpers'
import guard from '@/guard'

export default {
  name: 'TableTop',
  components: {
    BButton,
    BCol,
    BRow,
    BFormInput,

    vSelect,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  props: {
    addLabel: {
      type: String,
      default: 'shared.button.add',
    },
    showAddButton: {
      type: Boolean,
      default: false,
    },
    showFilterButton: {
      type: Boolean,
      default: false,
    },
    showSearch: {
      type: Boolean,
      default: false,
    },
    addButtonRoles: {
      type: Array,
      default: () => {},
    },
    limit: {
      type: Number,
      default: 10,
    },
    query: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      limitOptions: [5, 10, 25, 50, 100],
      queryTimeout: null,
    }
  },
  computed: {
    me() {
      return cloneNested(this.$store.getters['auth/getUser'])
    },
  },
  methods: {
    hasAtLeastOneRole: guard.hasAtLeastOneRole,
    changeLimitEvent(value) {
      this.$emit('change:limit', value)
    },
    onQueryChange(query) {
      if (query.length === 0) {
        this.$emit('change:query', '')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>
